import {Icon} from '@iconify/react';
import React from 'react';
import {Container, Dropdown, Image, Nav, Navbar} from 'react-bootstrap';
import {useLocation, useNavigate} from 'react-router-dom';
import mahindraLogo from '../../assets/New-m-logo.svg';
import './style.css';

const CommonHeader = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const isSupport = location.pathname.includes('/public/support');
  const isRaiseTicket = location.pathname.includes('/raise-ticket');

  const handleSupportClick = () => {
    navigate('/public/support');
  };

  const handleRaiseTicketClick = () => {
    navigate('/raise-ticket');
  };

  const handleLocateUs = () => {
    window.open('https://www.mahindrahomefinance.com/locations/', '_blank');
  };

  return (
    <Navbar expand="lg" style={{height: '10vh', backgroundColor: '#e6e7e8'}}>
      <Container>
        <Navbar.Brand href="/" className="bR-6 p-0">
          <Image className="header-logo" src={mahindraLogo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {/* Navbar content */}
        <Navbar.Collapse id="basic-navbar-nav">
          {/* Right side - Links */}
          <Nav className="ms-auto align-items-start align-items-md-center">
            <Nav.Link className="text-grey fw-bold me-4 " onClick={handleLocateUs}>
              <Icon icon="carbon:location-filled" className=" text-danger fs-5" /> Locate us
            </Nav.Link>
            <Dropdown className="avtar-dropdown-wrapper me-2" menuvariant="light">
              <Dropdown.Toggle variant="transparent" className="text-grey  px-0 border-0  helpbtn" id="dropdown-basic">
                <Icon icon="streamline:customer-support-1-solid" className="fs-5 text-danger me-1" /> Help & Support
              </Dropdown.Toggle>
              <Dropdown.Menu align="start" className="px-2 py-3 bg-white profile-dropdown shadow helpandsupport">
                <Dropdown.Item className="pb-1 pt-2 ps-3 pe-3 fs-14 text-dark header-dropdown" onClick={handleSupportClick} active={isSupport}> <Icon icon="ic:sharp-phone" className="fs-5 text-danger me-2" />  Support</Dropdown.Item>
                {/* <Dropdown.Item className="pb-1 pt-2 ps-3 pe-3 fs-14 text-dark header-dropdown" onClick={handleRaiseTicketClick} active={isRaiseTicket}> <Icon icon="ph:ticket" className="fs-5 text-danger me-2" /> Raise a ticket</Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CommonHeader;
