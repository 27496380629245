/* eslint-disable no-catch-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable no-negated-condition */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import mahindraInstance from './instance';
import {getRefreshToken} from '../../redux/thunks/auth';


const HttpInterceptor = ({children}) => {
  const navigate = useNavigate();
  let isRefreshing = false;
  let refreshQueue = [];
  const accessToken = sessionStorage.getItem('accessToken');

  if (accessToken) {
    mahindraInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    mahindraInstance.defaults.headers.common.Authorization = '';
  }

  mahindraInstance.defaults.timeout = 5000 * 20;
  mahindraInstance.defaults.timeoutErrorMessage = 'Network Error';

  const dispatch = useDispatch();

  const onRequest = config => config;
  //   config.baseURL = env.REACT_APP_BASE_URL;


  const onRequestError = async error => {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
      console.clear();
    }
    return Promise.reject(error);
  };

  const onResponse = response => {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
      console.clear();
    }
    return response;
  };

  const onResponseError = async error => {
    const originalRequest = error.config;

    if (originalRequest.url.includes('/oauth/token') || originalRequest.url.includes('/user/logout')) {
      navigate('/signin');
      //   clearStorage();
      return Promise.reject(error);
    }

    if (error.response?.status === 402 || error.response?.status === 451) {
      navigate('/signin');
      //   clearStorage();
      return Promise.reject(error);
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const body = {
            'grant_type': 'refresh_token',
            'refresh_token': sessionStorage.getItem('refresh_token'),
            'client_id': process.env.REACT_APP_CLIENT_ID,
            'client_secret': process.env.REACT_APP_CLIENT_SECRET,
          };
          const response = await dispatch(getRefreshToken(body));

          if (response.status === 200) {
            if (response.data.refresh_token) {
              sessionStorage.setItem('accessToken', response.data.access_token);
              sessionStorage.setItem('refresh_token', response.data.refresh_token);
              sessionStorage.setItem('expires_in', response.data.expires_in);
            } else {
              sessionStorage.setItem('accessToken', response.data.access_token);
            }

            mahindraInstance.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;
            originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;
            refreshQueue.forEach(queuedRequest => {
              const {originalRequest, resolve, reject} = queuedRequest;
              const newRequestConfig = {...originalRequest, headers: {}};
              newRequestConfig.headers.Authorization = `Bearer ${response.data.access_token}`;
              mahindraInstance(newRequestConfig)
                .then(resolve)
                .catch(reject);
            });

            refreshQueue = [];

            return mahindraInstance(originalRequest);
          }
          navigate('/signin');
          sessionStorage.clear();
          return Promise.reject(error);

        } catch (error) {
          isRefreshing = false;
          return Promise.reject(error);
        } finally {
          isRefreshing = false;
        }
      } else {
        if (originalRequest.url.includes('/oauth/token')) {
          navigate('/signin');
          //   clearStorage();
          return Promise.reject(error);
        }
        return new Promise((resolve, reject) => {
          const newRequestConfig = {...originalRequest, headers: {}};
          newRequestConfig.headers.Authorization = `Bearer ${sessionStorage.getItem(
            'accessToken'
          )}`;
          refreshQueue.push({originalRequest: newRequestConfig, resolve, reject});
        });
      }
    }

    if (error.response?.status === 403) {
      dispatch(
        // setAlert({
        //   show: true,
        //   message: 'You do not have access to some features. please contact us.',
        //   status: 'error',
        // })
      );
    }

    return Promise.reject(error);
  };

  mahindraInstance.interceptors.request.use(onRequest, onRequestError);
  mahindraInstance.interceptors.response.use(onResponse, onResponseError);

  return <>{children}</>;
};

export default HttpInterceptor;
