import React from 'react';
import './style.css';

const Loader = () => (
  <div className="loader-container">
    <img
      src={require('../../assets/mahindra-spinner.png')}
      className="flipping-image"
      alt="Mahindra Logo" />
  </div>
);

export default Loader;
