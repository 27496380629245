import {setLogin} from '../slices/authSlice';
import mahindraInstance from '../../config/axiosConfig/instance';
import {setButtonSpinner, setCustomerDetails, setCustomerDetailsLoader, setCustomerSearchDetails, setLoanDetails, setLoanDetailsLoader} from '../slices/loanDetailsSlice';
import alertManager from '../../components/alert/alertManager';
import {setDownloadLoader, setLoader} from '../slices/layoutSlice';
import {setAdminDetails} from '../slices/adminSlice';

export const checkAccessToken = () => async dispatch => {
  try {
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken) {
      dispatch(setLogin(true));
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const sendOtp = body => async dispatch => {
  try {
    dispatch(setButtonSpinner({buttonSpinner: true}));
    const response = await mahindraInstance.post('/api/v1/login', body);
    return response;
  } catch (error) {
    dispatch(setButtonSpinner({buttonSpinner: false}));
    return error.response?.data;
  } finally {
    dispatch(setButtonSpinner({buttonSpinner: false}));
  }
};

export const verifyOtp = body => async dispatch => {
  try {
    dispatch(setButtonSpinner({buttonSpinner: true}));
    const response = await mahindraInstance.post('/oauth/token', body);
    return response;
  } catch (error) {
    dispatch(setButtonSpinner({buttonSpinner: false}));
    if (error.response) {
      return error.response.data;
    }
    return alertManager('error', 'Invalid OTP');
  } finally {
    dispatch(setButtonSpinner({buttonSpinner: false}));
  }
};


export const getRefreshToken = body => async dispatch => {
  try {
    const response = await mahindraInstance.post('/oauth/token', body);
    return response;
  } catch (error) {
    console.log('getRefreshToken server side error.', error);
    return error.response.data;
  }
};

export const logOut = body => async dispatch => {
  try {
    const response = await mahindraInstance.post('/api/v1/user/logout');
    if (response.status === 200) {
      dispatch(setLoanDetails({}));
      dispatch(setCustomerDetails({}));
      dispatch(setLoanDetailsLoader({loader: true}));
      dispatch(setCustomerDetailsLoader({loader: false}));
      dispatch(setButtonSpinner({buttonSpinner: false}));
      dispatch(setLogin(false));
      dispatch(setLoader({loader: false}));
      dispatch(setDownloadLoader({loader: false}));
      sessionStorage.clear();
      dispatch(setCustomerSearchDetails({}));
    }
    return response;
  } catch (error) {
    console.log('logOut server side error.', error);
    return error.response.data;
  }
};

export const adminLogin = body => async dispatch => {
  try {
    dispatch(setButtonSpinner({buttonSpinner: true}));
    const response = await mahindraInstance.post('/oauth/token', body);
    if (response?.status === 200) {
      dispatch(setAdminDetails({
        username: body.username,
      }));
    }
    return response;
  } catch (error) {
    dispatch(setButtonSpinner({buttonSpinner: false}));
    if (error.response) {
      return error.response.data;
    }
    return alertManager('error', 'Invalid Credentials');
  } finally {
    dispatch(setButtonSpinner({buttonSpinner: false}));
  }
};

