import React from 'react';
import {Col, Container, Navbar, Row} from 'react-bootstrap';
import {Icon} from '@iconify/react';
const Footer = () => {

  const getYearFromDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    return year;
  };

  return (
    <Navbar expand="lg" className="footer footer-color" fixed="bottom">
      <Container fluid className="justify-content-between py-3">
        <Row className="w-100 text-center justify-content-center justify-content-md-between align-items-center px-3">
          <Col xs={12} md={12} lg={10} className="pe-0 text-center text-md-start">
            <div className="fs-12 text-white">Copyright © Mahindra Rural Housing Finance Ltd {getYearFromDate()} - All Rights Reserved</div>
          </Col>
          <Col xs={12} md={12} lg={2} className="pe-0 ">
            <div className="fs-15 text-white d-flex justify-content-center justify-content-lg-evenly pt-3 pt-md-0 footer-icons">
              <div className="me-2" style={{backgroundColor: '#FFF', width: '25px', height: '25px', borderRadius: '50%'}}>
                <a href="https://www.facebook.com/MahindraHomeFin/" data-bs-toggle="tooltip" data-bs-placement="top" title="Facebook" target="_blank"><Icon icon="fontisto:facebook" className="fs-18" style={{color: 'red'}} /></a>
              </div>
              <div className="me-2" style={{backgroundColor: '#FFF', width: '25px', height: '25px', borderRadius: '50%'}}>
                <a href="https://www.instagram.com/mahindra.homefinance/" data-bs-toggle="tooltip" data-bs-placement="top" title="Instagram" target="_blank"><Icon icon="mdi:instagram" className="fs-18" style={{color: 'red'}} /></a>
              </div>
              <div className="me-2" style={{backgroundColor: '#FFF', width: '25px', height: '25px', borderRadius: '50%'}}>
                <a href="https://www.linkedin.com/company/mahindrahomefinance/" data-bs-toggle="tooltip" data-bs-placement="top" title="Linkedin" target="_blank"><Icon icon="ri:linkedin-fill" className="fs-18" style={{color: 'red'}} /></a>
              </div>
              <div className="me-2" style={{backgroundColor: '#FFF', width: '25px', height: '25px', borderRadius: '50%'}}>
                <a href="https://twitter.com/MahindraHomeFin" data-bs-toggle="tooltip" data-bs-placement="top" title="X-Twitter" target="_blank"><Icon icon="ri:twitter-x-fill" className="fs-18" style={{color: 'red'}} /></a>
              </div>
              <div className="me-2" style={{backgroundColor: '#FFF', width: '25px', height: '25px', borderRadius: '50%'}}>
                <a href="https://www.youtube.com/@mahindrahomefinance4492" data-bs-toggle="tooltip" data-bs-placement="top" title="Youtube" target="_blank"><Icon icon="mingcute:youtube-fill" className="fs-18" style={{color: 'red'}} /></a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default Footer;
