import {toast} from 'react-toastify';

const alertManager = (variant, errorMessage) => {
  switch (variant) {
    case 'error':
      toast.error(errorMessage);
      break;
    case 'success':
      toast.success(errorMessage);
      break;
    default:
      toast(errorMessage);
      break;
  }

};

alertManager.displayName = 'alertManager';
export default alertManager;
