import {combineReducers, configureStore} from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import thunkMiddleware from 'redux-thunk';
import loanDetailsReducer from './slices/loanDetailsSlice';
import layoutReducer from './slices/layoutSlice';
import adminReducer from './slices/adminSlice';


const rootReducer = combineReducers({
  auth: authReducer,
  loanDetails: loanDetailsReducer,
  layout: layoutReducer,
  admin: adminReducer,
});

export default configureStore({
  reducer: rootReducer,
  middleware: [thunkMiddleware],
});
