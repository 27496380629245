import React from 'react';
import Button from 'react-bootstrap/Button';
import './style.css';

const Buttons = ({variant, onClick, type, disabled, size, className, children, title, icon}) => (
  <Button
    variant={variant}
    onClick={onClick}
    disabled={disabled}
    size={size}
    type={type ?? 'button'}
    className={className}>
    {icon}
    {children ?? title}
  </Button>
);

export default Buttons;
