import React from 'react';
import './style.css';
const InputField = ({register, name, id, placeholder, readonly, value, style, type, icon, label, labelClass, formClass}) => (
  // <div className="form-group input-field">
  <div className={formClass}>
    <label className={labelClass} htmlFor={name}>{label}</label>
    <input type={type} className="form-control" style={style} name={name} id={id} placeholder={placeholder} value={value} {...register(name)} readOnly={readonly} />
    {icon}
  </div>
);

export default InputField;
