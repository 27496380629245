import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Support from '../pages/support/Support';
import MyQuery from '../pages/query/MyQuery';
import PublicLayout from '../components/layout/PublicLayout';

const AuthLayout = React.lazy(() => import('../components/layout/AuthLayout'));
const Layout = React.lazy(() => import('../pages/layout/Layout'));
const Signin = React.lazy(() => import('../pages/signin'));
const LoginForm = React.lazy(() => import('../components/authentication/loginForm/LoginForm'));
const OTPForm = React.lazy(() => import('../components/authentication/otpForm/OTPForm'));
const AdminLoginForm = React.lazy(() => import('../components/authentication/adminLoginForm/AdminLoginForm'));
const MultipleAccountForm = React.lazy(() => import ('../components/authentication/multipleAccountForm/MultipleAccountForm'));

const PublicRoutes = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="/*" element={<Layout />} />
    </Route>
    <Route path="signin" element={<Signin />} >
      <Route index element={<LoginForm />} />
      <Route path="verify-otp" element={<OTPForm />} />
      <Route path="admin" element={<AdminLoginForm />} />
      <Route path="select-account" element={<MultipleAccountForm />} />
    </Route>
    <Route element={<PublicLayout url="/support" />}>
      <Route path="/public/support" element={<Support />} />
    </Route >
    {/* <Route element={<PublicLayout url="/query" />}>
      <Route path="/raise-ticket" element={<MyQuery />} />
    </Route> */}
  </Routes>
);

export default PublicRoutes;
