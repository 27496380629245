import {Icon} from '@iconify/react';
import React from 'react';
import {Card, Col, Container, Row} from 'react-bootstrap';
import {MdOutlineAttachEmail} from 'react-icons/md';
import {useLocation} from 'react-router-dom';
import CommonHeader from '../../components/common/CommonHeader';
import './style.css';
const Support = () => {
  const location = useLocation();
  const isPublic = location.pathname.includes('public/support');

  return (
    <>
      {isPublic && <CommonHeader />}
      <Container className="support">
        <Row className="mt-3">
          <Col xs={12} md={12} lg={12} className="bg-white zI-2">
            <Container className="text-white bg-white h-396">
              <Row className="pt-4 pb-4 d-flex justify-content-center">
                <Col xs={6} md={3} lg={3} className=" ">
                  <Card className="text-center p-3 h-240 my-3">
                    <div className="mt-3"><MdOutlineAttachEmail className="fs-44 text-red" /></div>
                    <div className="fs-14 fw-bold mt-3 mb-3">Email</div>
                    <div className="mb-3"><a href="mailto:Mrhfl.cc@mahindra.com" type="email" className="text-red fs-17 fw-bold">Mrhfl.cc@mahindra.com</a></div>
                  </Card>
                </Col>
                <Col xs={6} md={3} lg={3}>
                  <Card className="text-center p-3 h-240 my-3">
                    <div className="mt-3"><Icon icon="la:phone-volume" className="fs-44 text-red" /></div>
                    <div className="fs-14 fw-bold mt-3 mb-3">Toll Free</div>
                    <div className="mb-2"><a href="tel:+1800 233 5333" type="phone" className="text-red fs-17 fw-bold">1800 233 5333</a></div>
                    <div className="fs-13 text-grey fw-bold mb-3">
                      (Mon to Sat, 9.30 AM to 6 PM)<br />
                      (Except National Holidays)
                    </div>
                  </Card>
                </Col>
                <Col xs={6} md={3} lg={3}>
                  <Card className="text-center p-3 h-240 my-3">
                    <div className="mt-3"><Icon icon="streamline:whatsapp" className="fs-44 text-red" /></div>
                    <div className="fs-14 fw-bold mt-3 mb-3">WhatsApp</div>
                    <div className="mb-3"><a href="https://wa.me/918657567212" type="phone" className="text-red fs-17 fw-bold" target="_blank">8657567212</a></div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>

  );
};

export default Support;
