import React from 'react';
import './App.css';
import PublicRoutes from './routes/PublicRoutes';
import Loader from './components/layout/Loader';
import Footer from './components/layout/Footer';

function App() {
  return (
    <React.Suspense fallback={<Loader />}>
      <PublicRoutes />
      <Footer />
    </React.Suspense>
  );
}

export default App;
