import {combineReducers, createSlice} from '@reduxjs/toolkit';

const initialAdminDetails = {
  username: '',
};

export const adminDetailsSlice = createSlice({
  name: 'admin',
  initialState: initialAdminDetails,
  reducers: {
    setAdminDetails: (state, action) => action.payload,
  },
});
export const {setAdminDetails} = adminDetailsSlice.actions;
export const SelectAdminDetails = state => state.admin.adminDetails;


const adminReducer = combineReducers({
  adminDetails: adminDetailsSlice.reducer,
});

export default adminReducer;
