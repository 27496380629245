import {combineReducers, createSlice} from '@reduxjs/toolkit';


export const loginSlice = createSlice({
  name: 'login',
  initialState: false,
  reducers: {
    setLogin: (state, action) => action.payload,
  },
});
export const {setLogin} = loginSlice.actions;
export const SelectLogin = state => state.auth.login;

export const cookieAlert = createSlice({
  name: 'cookieAlert',
  initialState: false,
  reducers: {
    setCookieAlert: (state, action) => action.payload,
  },
});
export const {setCookieAlert} = cookieAlert.actions;
export const SelectCookieAlert = state => state.auth.cookieAlert;

const authReducer = combineReducers({
  login: loginSlice.reducer,
  cookieAlert: cookieAlert.reducer,
});

export default authReducer;
