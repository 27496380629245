import React, {useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';

const PublicLayout = ({url}) => {
  const accessToken = sessionStorage.getItem('accessToken');
  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      navigate(url);
    }
  }, []);

  return (
    <Outlet />
  );
};

export default PublicLayout;
