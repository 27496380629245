import {combineReducers, createSlice} from '@reduxjs/toolkit';

const initialLoanDetails = {};
const initialCustomerDetails = {};
const initialCustomerSearchDetails = {};
const initialModeOfPayment = {};
const initialMultipleAccounts = [];
const initialExposerDetails = [];

export const loanDetailsSlice = createSlice({
  name: 'loan',
  initialState: initialLoanDetails,
  reducers: {
    setLoanDetails: (state, action) => action.payload,
  },
});
export const {setLoanDetails} = loanDetailsSlice.actions;
export const SelectLoanDetails = state => state.loanDetails.loan;

export const customerDetailsSlice = createSlice({
  name: 'customerDetails',
  initialState: initialCustomerDetails,
  reducers: {
    setCustomerDetails: (state, action) => action.payload,
  },
});
export const {setCustomerDetails} = customerDetailsSlice.actions;
export const SelectCustomerDetails = state => state.loanDetails.customerDetails;

export const customerSearchSlice = createSlice({
  name: 'customerSearchDetails',
  initialState: initialCustomerSearchDetails,
  reducers: {
    setCustomerSearchDetails: (state, action) => action.payload,
  },
});
export const {setCustomerSearchDetails} = customerSearchSlice.actions;
export const SelectCustomerSearchDetails = state => state.loanDetails.customerSearchDetails;


export const loanDetailsLoaderSlice = createSlice({
  name: 'loanDetailsLoader',
  initialState: {
    loader: true,
  },
  reducers: {
    setLoanDetailsLoader: (state, action) => action.payload,
  },
});

export const {setLoanDetailsLoader} = loanDetailsLoaderSlice.actions;
export const SelectLoanDetailsLoader = state => state.loanDetails.loanDetailsLoader;


export const customerDetailsLoaderSlice = createSlice({
  name: 'customerDetailsLoader',
  initialState: {
    loader: false,
  },
  reducers: {
    setCustomerDetailsLoader: (state, action) => action.payload,
  },
});

export const {setCustomerDetailsLoader} = customerDetailsLoaderSlice.actions;
export const SelectCustomerDetailsLoader = state => state.loanDetails.customerDetailsLoader;

export const buttonSpinnerSlice = createSlice({
  name: 'buttonSpinner',
  initialState: {
    buttonSpinner: false,
  },
  reducers: {
    setButtonSpinner: (state, action) => action.payload,
  },
});

export const {setButtonSpinner} = buttonSpinnerSlice.actions;
export const SelectButtonSpinner = state => state.loanDetails.buttonSpinner;

export const disbursementHistoryDetailsLoaderSlice = createSlice({
  name: 'disbursementHistoryLoader',
  initialState: {
    loader: true,
  },
  reducers: {
    setDisbursementHistoryLoader: (state, action) => action.payload,
  },
});

export const {setDisbursementHistoryLoader} = disbursementHistoryDetailsLoaderSlice.actions;
export const SelectDisbursementHistoryLoader = state => state.loanDetails.disbursementHistoryLoader;

export const modeOfPaymentSlice = createSlice({
  name: 'modeOfPayment',
  initialState: initialModeOfPayment,
  reducers: {
    setModeOfPayment: (state, action) => action.payload,
  },
});
export const {setModeOfPayment} = modeOfPaymentSlice.actions;
export const SelectModeOfPayment = state => state.loanDetails.modeOfPaymentDetails;

export const modeOfPaymentLoaderSlice = createSlice({
  name: 'modeOfPaymentLoader',
  initialState: {
    loader: true,
  },
  reducers: {
    setModeOfPaymentLoader: (state, action) => action.payload,
  },
});

export const {setModeOfPaymentLoader} = modeOfPaymentLoaderSlice.actions;
export const SelectModeOfPaymentLoader = state => state.loanDetails.modeOfPaymentLoader;

export const multipleAccountDetailsSlice = createSlice({
  name: 'multipleAccounts',
  initialState: initialMultipleAccounts,
  reducers: {
    setMultipleAccountDetails: (state, action) => action.payload,
  },
});
export const {setMultipleAccountDetails} = multipleAccountDetailsSlice.actions;
export const SelectMultipleAccountDetails = state => state.loanDetails.multipleAccountsDetails;

export const exposerDetailsSlice = createSlice({
  name: 'exposer',
  initialState: initialExposerDetails,
  reducers: {
    setExposerDetails: (state, action) => action.payload,
  },
});
export const {setExposerDetails} = exposerDetailsSlice.actions;
export const SelectExposeDetails = state => state.loanDetails.exposerDetails;


const loanDetailsReducer = combineReducers({
  loan: loanDetailsSlice.reducer,
  loanDetailsLoader: loanDetailsLoaderSlice.reducer,
  customerDetails: customerDetailsSlice.reducer,
  customerDetailsLoader: customerDetailsLoaderSlice.reducer,
  buttonSpinner: buttonSpinnerSlice.reducer,
  customerSearchDetails: customerSearchSlice.reducer,
  disbursementHistoryLoader: disbursementHistoryDetailsLoaderSlice.reducer,
  modeOfPaymentDetails: modeOfPaymentSlice.reducer,
  modeOfPaymentLoader: modeOfPaymentLoaderSlice.reducer,
  multipleAccountsDetails: multipleAccountDetailsSlice.reducer,
  exposerDetails: exposerDetailsSlice.reducer,
});

export default loanDetailsReducer;
