import {combineReducers, createSlice} from '@reduxjs/toolkit';

export const mhfSpinnerSlice = createSlice({
  name: 'mhfSpinner',
  initialState: {
    loader: false,
  },
  reducers: {
    setLoader: (state, action) => action.payload,
  },
});

export const {setLoader} = mhfSpinnerSlice.actions;
export const SelectMhfLoader = state => state.layout.mhfSpinner;

export const downloadLoaderSlice = createSlice({
  name: 'downloadLoader',
  initialState: {
    loader: false,
  },
  reducers: {
    setDownloadLoader: (state, action) => action.payload,
  },
});

export const {setDownloadLoader} = downloadLoaderSlice.actions;
export const SelectDownloadLoader = state => state.layout.downloadLoader;

export const transactionDetailsLoaderSlice = createSlice({
  name: 'transactionDetailsLoader',
  initialState: {
    loader: true,
  },
  reducers: {
    setTransactionDetailsLoader: (state, action) => action.payload,
  },
});

export const {setTransactionDetailsLoader} = transactionDetailsLoaderSlice.actions;
export const SelectTransactionDetailsLoader = state => state.layout.transactionDetailsLoader;

export const queryRequestDetailsLoaderSlice = createSlice({
  name: 'queryRequestDetailsLoader',
  initialState: {
    loader: true,
  },
  reducers: {
    setQueryRequestDetailsLoader: (state, action) => action.payload,
  },
});

export const {setQueryRequestDetailsLoader} = queryRequestDetailsLoaderSlice.actions;
export const SelectQueryRequestDetailsLoader = state => state.layout.queryRequestDetailsLoader;

export const queryStatusLoaderSlice = createSlice({
  name: 'queryStatusLoader',
  initialState: {
    loader: false,
  },
  reducers: {
    setQueryStatusLoader: (state, action) => action.payload,
  },
});

export const {setQueryStatusLoader} = queryStatusLoaderSlice.actions;
export const SelectQueryStatusLoader = state => state.layout.queryStatusLoader;

export const auditTrailLoaderSlice = createSlice({
  name: 'auditTrailLoader',
  initialState: {
    loader: false,
  },
  reducers: {
    setAuditTrailLoader: (state, action) => action.payload,
  },
});

export const {setAuditTrailLoader} = auditTrailLoaderSlice.actions;
export const SelectAuditTrailLoader = state => state.layout.auditTrailLoader;

export const multipleAccountLoaderSlice = createSlice({
  name: 'multipleAccountLoader',
  initialState: {
    loader: false,
  },
  reducers: {
    setMultipleAccountLoader: (state, action) => action.payload,
  },
});

export const {setMultipleAccountLoader} = multipleAccountLoaderSlice.actions;
export const SelectMultipleAccountLoader = state => state.layout.multipleAccountLoader;

export const downloadSoaLoaderSlice = createSlice({
  name: 'downloadSoaLoader',
  initialState: {
    loader: false,
  },
  reducers: {
    setDownloadSoaLoader: (state, action) => action.payload,
  },
});

export const {setDownloadSoaLoader} = downloadSoaLoaderSlice.actions;
export const SelectDownloadSoaLoader = state => state.layout.soaDownloadLoader;

export const downloadPicLoaderSlice = createSlice({
  name: 'downloadPicLoader',
  initialState: {
    loader: false,
  },
  reducers: {
    setDownloadPicLoader: (state, action) => action.payload,
  },
});

export const {setDownloadPicLoader} = downloadPicLoaderSlice.actions;
export const SelectDownloadPicLoader = state => state.layout.picDownloadLoader;

const layoutReducer = combineReducers({
  mhfSpinner: mhfSpinnerSlice.reducer,
  downloadLoader: downloadLoaderSlice.reducer,
  transactionDetailsLoader: transactionDetailsLoaderSlice.reducer,
  queryRequestDetailsLoader: queryRequestDetailsLoaderSlice.reducer,
  queryStatusLoader: queryStatusLoaderSlice.reducer,
  auditTrailLoader: auditTrailLoaderSlice.reducer,
  multipleAccountLoader: multipleAccountLoaderSlice.reducer,
  soaDownloadLoader: downloadSoaLoaderSlice.reducer,
  picDownloadLoader: downloadPicLoaderSlice.reducer,
});

export default layoutReducer;

